<template>
  <textarea
    ref="textarea"
    v-bind="$attrs"
    :value="modelValue"
    @input="onInput"
    :style="{ height: textareaHeight }"
  ></textarea>
</template>

<script>
export default {
  name: 'AutoGrowTextarea',
  props: {
    modelValue: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      textareaHeight: 'auto',
    };
  },
  methods: {
    adjustHeight() {
      const textarea = this.$refs.textarea;
      textarea.style.height = 'auto'; // Reset the height
      textarea.style.height = textarea.scrollHeight + 'px'; // Set the height to the scroll height
      this.textareaHeight = textarea.style.height;
    },
    onInput(event) {
      this.$emit('update:modelValue', event.target.value);
      this.adjustHeight();
    }
  },
  mounted() {
    this.adjustHeight(); // Adjust the height on mount
  },
  watch: {
    modelValue() {
      this.$nextTick(this.adjustHeight); // Adjust the height when the value changes
    }
  }
};
</script>

<style scoped>
textarea {
  overflow: hidden; /* Hide the scrollbar */
  resize: none; /* Prevent manual resizing */
}
</style>
