

<script setup>
import { onMounted, ref, inject } from 'vue';
import ModalForm from '../elements/ModalForm.vue';
import ErrorDisplay from '../elements/ErrorDisplay.vue';

const props = defineProps({
    subscription: Number | String
});


onMounted(() => {
    $('#wordlist-preset-modal').on('hidden.bs.modal', function (event) {
        resetForm();
    });
});

const env = inject('env', {
    baseAnglicisms: null,
    baseGender: null
});

const emit = defineEmits(['generated']);

const errors = ref({});

const values = ref([
    {
        label: 'Corporate Formulierungen',
        name: 'corporate-text-pruefung',
        checked: false,
        brandFit: false,
        request: {
            title: "Corporate Formulierungen",
            description: "Bevorzugte Formulierungen gehören zu einer Markensprache. TEO hilft Ihnen, diese Formulierungen zu nutzen.",
            relation_type: 2,
            active: 1,
            use_description: 1,
            use_for_ct: 1,
            search_mode: 2,
            scale_benchmark: '',
            title_root_entry: '',
            title_entries: '',
            name: "corporate-text-pruefung",
            order: 1,
            ref_word_list_id: ''
        },
        requestState: 0
    }, {
        label: 'Corporate Schreibweisen',
        name: 'corporate-schreibweisen',
        checked: false,
        brandFit: false,
        request: {
            title: "Corporate Schreibweisen",
            description: "Verbindliche Schreibweisen gehören zu einer Markensprache. TEO hilft Ihnen, diese Schreibweisen einzuhalten.",
            relation_type: 3,
            active: 1,
            use_description: 0,
            use_for_ct: 1,
            prioritize_title: 1,
            search_mode: 2,
            scale_benchmark: '',
            title_root_entry: '',
            title_entries: '',
            name: "corporate-schreibweisen",
            order: 2,
            ref_word_list_id: ''
        },
        requestState: 0
    }, {
        label: 'weitere Corporate Regeln',
        name: 'corporate-text-rules',
        checked: false,
        brandFit: false,
        request: {
            title: "weitere Corporate Regeln",
            description: "Definieren Sie noch mehr Formulierungs- und Stilvorgaben, wie die Schreibweisen von Telefon-Nummern, Währungen oder bestimmte Datumsformate. Mit TEO behalten Sie auch diese Regeln stets im Blick.",
            relation_type: 5,
            active: 1,
            use_description: 1,
            use_for_ct: 1,
            search_mode: 2,
            scale_benchmark: '',
            title_root_entry: 'Keywörter',
            title_entries: '',
            name: "corporate-text-rules",
            order: 0,
            ref_word_list_id: ''
        },
        requestState: 0
    }, {
        label: 'Powerwörter',
        name: 'power-words',
        checked: false,
        brandFit: false,
        request: {
            title: "Powerwörter",
            description: "Sprache ist Teil der Marken-Identität und trägt ganz wesentlich zu einem uniquen Marken-Erlebnis bei. Deshalb markiert TEO „Powerwörter“, die Sie für Ihre Markensprache als besonders wichtig definieren.",
            relation_type: 0,
            active: 1,
            use_description: 0,
            use_for_ct: 0,
            scale_type: 1,
            search_mode: 1,
            scale_benchmark: 1.25,
            title_root_entry: '',
            title_entries: '',
            name: "power-words",
            order: 3,
            ref_word_list_id: ''
        },
        requestState: 0
    }, {
        label: 'Whitelist für Fachbegriffe',
        name: 'whitelist',
        checked: false,
        brandFit: false,
        request: {
            title: "Whitelist für Fachbegriffe",
            description: "whitelist",
            relation_type: 4,
            active: 1,
            use_description: 0,
            use_for_ct: 0,
            search_mode: 0,
            scale_benchmark: '',
            title_root_entry: '',
            title_entries: '',
            name: "whitelist",
            order: 0,
            ref_word_list_id: ''
        },
        requestState: 0
    },
    {
        label: 'Anglizismen',
        name: 'anglizismen',
        checked: false,
        brandFit: false,
        request: {
            title: "Anglizismen",
            description: "Englische Begriffe gehören heute zum Sprachgebrauch. Doch die Akzeptanz ist in den verschiedenen Zielgruppen unterschiedlich. Deshalb markiert TEO Wörter, die aus der englischen Sprache kommen. So können Sie bei Bedarf die Begriffe ersetzen – und vermeiden Missverständnisse oder Reaktanzen.",
            relation_type: 2,
            active: 1,
            use_description: 1,
            use_for_ct: 0,
            search_mode: 0,
            scale_benchmark: '',
            title_root_entry: '',
            title_entries: '',
            name: "anglizismen",
            order: 2,
            ref_word_list_id: env.baseAnglicisms != null ? env.baseAnglicisms : ''
        },
        requestState: 0
    }, {
        label: 'Gender-Thesaurus',
        name: 'gender-thesaurus',
        checked: false,
        brandFit: false,
        request: {
            title: "Gender-Thesaurus",
            description: "Sprache ist ein Spiegel der Gesellschaft. Und gesellschaftliche Vielfalt ist heute ein wichtiger Aspekt. Deshalb zeigt Ihnen TEO, wie man geschlechterneutral formulieren kann. Das hilft Ihnen dabei, sensibel und frei von Diskriminierungen zu formulieren.",
            relation_type: 2,
            active: 1,
            use_description: 1,
            use_for_ct: 0,
            prioritize_title: 1,
            search_mode: 0,
            scale_benchmark: '',
            title_root_entry: 'Genderspezifische Schreibweisen',
            title_entries: 'Neutrale Schreibweisen',
            name: "gender-thesaurus",
            order: 3,
            ref_word_list_id: env.baseGender != null ? env.baseGender : ''
        },
        requestState: 0
    },
]);


// onMounted(() => {
//     });
function show() {
    console.log(props.subscription);
    if (props.subscription) {
        $('#wordlist-preset-modal').modal();
    }
}

function onFormSubmit() {
    for (let item of values.value) {
        if (item.checked) {
            item.requestState = 1;
        }
    }
    requestNext(0, (succ) => {
        emit('generated', succ);
        if (succ) {
            $('#wordlist-preset-modal').modal('hide');
        } else {
        }
    });
}

function resetForm() {

    // reset
    for (let item of values.value) {
        item.checked = false;
        item.brandFit = false;
        item.requestState = 0;
    }
}

function requestNext(pos, callback, withError = 0) {
    if (pos < values.value.length) {
        if (values.value[pos].requestState == 1) {
            values.value[pos].requestState = 2;
            createList(values.value[pos], (succ) => {
                if (succ) {
                    values.value[pos].requestState = 3;
                } else {
                    values.value[pos].requestState = 4;
                    withError = 1;
                }
                requestNext(pos + 1, callback, withError);
            })
        } else {
            requestNext(pos + 1, callback, withError);
        }
    } else {
        callback(!withError);
    }
}

function createList(item, callback) {
    const formData = new FormData();

    for (var key in item.request) {
        formData.append(key, item.request[key]);
    }
    formData.append('subscription_id', props.subscription);


    // if (formData.has('description') && !formData.get('description')) {
    //     formData.delete('description');
    // }
    axios.post('/ajax/wordLists', formData, {
    })
        .then((res) => {
            callback(1);
        })
        .catch(function (error) {
            if (error.response && error.response.data && error.response.data.errors) {
                errors.value = error.response.data.errors;
            }
            callback(0);
            console.error(error);
        })
}

</script>

<template>
    <button class="btn btn-primary" type="button" :disabled="subscription ? null : ''" @click.prevent="show">Listen
        aus Presets anlegen</button>
    <ModalForm modal-id="wordlist-preset-modal" modal-title="Mandanten Setup" modal-class="modal-lg"
        submit-text="Listen erstellen" dismiss-text="abbrechen" @form-submit="onFormSubmit">
        <div v-for="value in values">

            <div class="form-check mb-2">
                <label class="form-check-label">
                    <!-- <input type="hidden" :name="value.name" value="0" /> -->
                    <input class="form-check-input" type="checkbox" v-model="value.checked" />
                    {{ value.label }}
                    <span class="ml-4 text-secondary" v-show="value.requestState == 1">warten...</span>
                    <span class="ml-4 text-info" v-show="value.requestState == 2">erstellen...</span>
                    <span class="ml-4 text-success" v-show="value.requestState == 3">Liste erstellt</span>
                    <span class="ml-4 text-danger" v-show="value.requestState == 4">Fehler</span>
                </label>
                <label class="form-check-label ml-2" v-if="value.checked">
                    <input class="form-check-input" type="checkbox" v-model="value.brandFit" />
                    für Brand-Fit verwenden
                </label>
            </div>
        </div>
        <ErrorDisplay :errors="errors"></ErrorDisplay>
    </ModalForm>

</template>

<style scoped>

</style>
