<script setup>
import { onMounted, ref, watch, inject } from 'vue';
import { marked } from 'marked';

marked.setOptions({
    breaks: true
});

const env = inject('env', {
    name: '',
    initialContent: '',
});

const content = ref(env.initialContent);
const formattedContent = ref(marked(content.value));

onMounted(() => {
    content.value = env.initialContent;
});

watch(content, (newValue) => {
    formattedContent.value = marked(newValue);
});

</script>

<template>
    <div class="md-text-input">
        <textarea :name="env.name" v-model="content" class="form-control"></textarea>
        <div class="mt-3">
            Vorschau:
        </div>
        <div class="mt-1 md-text-preview" v-html="formattedContent"></div>
    </div>
</template>

<style scoped>
.md-text-input {
    display: flex;
    flex-direction: column;
}

.md-text-input textarea {
    width: 100%;
    height: 150px;
}

.md-text-input .md-text-preview {
    border: 1px solid #ccc;
    padding: 10px;
    background-color: #dddddd;
}
</style>
