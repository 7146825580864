const propFields = [
    {
        fieldName: 'context',
        fieldType: 'textarea',
        default: '',
        label: 'Kontext'
    },
    {
        fieldName: 'textBefore',
        fieldType: 'textarea',
        default: '',
        label: 'Text Before'
    },
    {
        fieldName: 'textAfter',
        fieldType: 'textarea',
        default: '',
        label: 'Text After'
    },
    {
        fieldName: 'abstractNouns',
        fieldType: 'textarea',
        default: '',
        label: 'Abstrakte Substantive',
        isAutomatic: true
    },
    {
        fieldName: 'simpleNouns',
        fieldType: 'textarea',
        default: '',
        label: 'Einfache Substantive',
        isAutomatic: true
    },
    {
        fieldName: 'longWords',
        fieldType: 'textarea',
        default: '',
        label: 'Lange Wörter',
        isAutomatic: true
    },
    {
        fieldName: 'mediumWords',
        fieldType: 'textarea',
        default: '',
        label: 'Mittellange Wörter',
        isAutomatic: true
    },
    {
        fieldName: 'longSentences',
        fieldType: 'checkbox',
        default: '',
        label: 'Enthält lange Sätze',
        isAutomatic: true
    },
    {
        fieldName: 'informal',
        fieldType: 'checkbox',
        default: false,
        label: 'Du-Ansparche'
    },
    {
        fieldName: 'analysisConfig',
        fieldType: 'textarea',
        validate: (value) => {
            try {
                JSON.parse(value);
                return true;
            } catch (error) {
                return false;
            }
        },
        default: "{\n    profile: 0,\n    audience: 0,\n    strategy: 0,\n    medium: 0\n}",
        label: 'Analysis Config'
    },
    {
        fieldName: 'analysisResult',
        fieldType: 'textarea',
        validate: (value) => {
            try {
                JSON.parse(value);
                return true;
            } catch (error) {
                return false;
            }
        },
        default: "{\n    tpi: 0,\n    vi: 0,\n    ai: 0\n}",
        label: 'Analysis Result'
    }
];

function getPropFieldArray(fieldNames) {
    const fields = [];
    fieldNames.forEach((field) => {
        const propField = propFields.find((propField) => propField.fieldName === field);
        if (propField) {
            fields.push(propField);
        } else {
            fields.push({
                fieldName: field,
                fieldType: 'text',
                default: "",
                label: field
            });
        }
    });
    return fields;
}

export default getPropFieldArray;
