<script setup>
import { onMounted, ref, watch, inject } from 'vue';

const env = inject('env', {
    providersJson: '',
    oldProvider: '',
    oldModel: '',
    oldTemperature: 1
});
const providers = ref([]);

onMounted(() => {
    providers.value = JSON.parse(env.providersJson);
    currentProviderName.value = env.oldProvider;
    model = env.oldModel;
});

const currentProvider = ref(null);
const currentProviderName = ref(null);
const currentTemperature = ref(env.oldTemperature);
let model = null;

watch(currentProviderName, (value) => {
    currentProvider.value = providers.value.find((provider) => provider.name === value);
});

</script>

<template>
    <div class="form-group">
        <label for="provider">Provider</label>
        <select v-model="currentProviderName" class="form-control" id="provider" name="provider">
            <option v-for="provider in providers" :value="provider.name">{{ provider.title }}</option>
        </select>
    </div>
    <div class="form-group">
        <label for="model">Model <span v-if="!currentProvider">(zuerst Provider auswählen)</span></label>
        <select v-model="model" class="form-control" id="model" name="model">
            <option v-if="currentProvider" v-for="model in currentProvider.models" :value="model">{{ model }}</option>
        </select>
    </div>
    <div class="form-group">
        <label for="temperature">Temperature
            <br>
            <span v-if="currentProvider" class="text-secondary">(zwischen {{ currentProvider?.min_temperature }}
            und {{ currentProvider?.max_temperature }},
            default ist {{ currentProvider?.default_temperature }}
            für {{ currentProvider.title }})</span></label>
        <input v-model="currentTemperature" type="number" class="form-control" id="temperature" name="temperature" step="0.1" />
    </div>
</template>

<style scoped></style>
