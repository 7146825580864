<script setup>

import { onMounted, ref, watch, inject } from 'vue';
import getPropFieldArray from './propFields';
import AutoGrowTextarea from '../elements/AutoGrowTextarea.vue';

const env = inject('env', {
    fieldName: 'prompt-data-json',
    promptJson: '',
    toolOptions: '',
    toolName: '',
    toolLabels: ''
});

const formData = ref({
    systemPrompt: '',
    samples: []
});

const promptJson = ref('');
const currentPropFields = ref([]);
const currentTool = ref('');
const toolOptions = ref([]);
const subcategory = ref('');
let toolLabels = {};

let buildJsonTimeout;

onMounted(() => {
    if (env.promptJson) {
        let data = JSON.parse(env.promptJson);
        data.items.forEach((item) => {
            if (item.type === 'system') {
                formData.value.systemPrompt = item.content;
            } else if (item.type === 'sample') {
                formData.value.samples.push({
                    content: item.content.text,
                    props: item.content.props,
                    response: item.response.text
                });
            } else {
                console.error('Unknown item type:', item.type);
            }
        });
        buildJson();
    }
    if (env.toolOptions) {
        toolOptions.value = JSON.parse(env.toolOptions);
    }
    if (env.toolName) {
        currentTool.value = env.toolName;
    }
    if (env.subcategory) {
        subcategory.value = env.subcategory;
    }
    if (env.toolLabels) {
        toolLabels = JSON.parse(env.toolLabels);
    }
});

watch(formData, buildJson, { deep: true });
watch(currentTool, () => {
    console.log('currentTool:', currentTool.value);
    console.log('toolOptions:', toolOptions.value);
    console.log('options for current tool:', toolOptions.value[currentTool.value]);
    currentPropFields.value = getPropFieldArray(toolOptions.value[currentTool.value]);
    console.log('currentPropFields:', currentPropFields.value);
    updatePropFieldsOfSamples();
});

function buildJson() {
    clearTimeout(buildJsonTimeout);
    buildJsonTimeout = setTimeout(() => {
        let result = {
            version: 1,
            items: [
                {
                    type: 'system',
                    content: formData.value.systemPrompt
                },
                ...formData.value.samples.map(sample => ({
                    type: 'sample',
                    content: {
                        text: sample.content,
                        props: sample.props
                    },
                    response: {
                        text: sample.response
                    }
                }))
            ]
        }
        promptJson.value = JSON.stringify(result);
    }, 300); // 300ms delay
}

function addSample() {
    let newSample = {
        content: '',
        props: {},
        response: ''
    };

    currentPropFields.value.forEach(field => {
        newSample.props[field.fieldName] = field.default;
    });

    formData.value.samples.push(newSample);
    buildJson();
}

function updatePropFieldsOfSamples() {
    currentPropFields.value.forEach(field => {
        formData.value.samples.forEach(sample => {
            if (!sample.props[field.fieldName]) {
                sample.props[field.fieldName] = field.default;
            }
        });
    });
}

</script>

<template>
    <div class="ai-prompt-data-form">
        <input type="hidden" :name="env.fieldName" v-model="promptJson" />
        <div class="form-group">
            <label for="system-prompt">Tool-Typ</label>
            <select class="form-control" v-model="currentTool" name="name">
                <option value="">Bitte wählen</option>
                <option v-for="(propNames, key) in toolOptions" :key="key" :value="key">
                    {{ toolLabels[key] ? toolLabels[key] : key }}
                </option>
            </select>
        </div>
        <div class="form-group">
            <label for="system-prompt">Zusatz-Kategorie</label>
            <input type="text" class="form-control" name="subcategory" v-model="subcategory" />
        </div>
        <div class="form-group">
            <label for="system-prompt">System Prompt</label>
            <!-- <textarea id="system-prompt" class="form-control" rows="3" v-model="formData.systemPrompt"
                required></textarea> -->
            <AutoGrowTextarea id="system-prompt" class="form-control" rows="3" v-model="formData.systemPrompt"
                required />

        </div>
        <div class="card">
            <div class="card-header">
                Beispiele
            </div>
            <div v-for="(sample, index) in formData.samples" :key="index" class="sample-container">
                <div class="form-group">
                    <label for="sample-content">User</label>
                    <AutoGrowTextarea id="sample-content" class="form-control" rows="3"
                        v-model="formData.samples[index].content" required />
                </div>
                <div class="card sample-prop-container">
                    <button class="btn btn-link" type="button" data-toggle="collapse"
                        :data-target="'#propFields-' + index" aria-expanded="true" aria-controls="propFields">
                        Zusatz-Daten
                    </button>
                    <div :id="'propFields-' + index" class="collapse">
                        <div v-for="field in currentPropFields" :key="field.fieldName" class="form-group">
                            <template v-if="field.fieldType === 'textarea'">
                                <label :for="'sample-' + field.fieldName + '-' + index">{{ field.label }} <span
                                        v-if="field.isAutomatic">(automatisch befüllt)</span></label>
                                <textarea :id="'sample-' + field.fieldName + '-' + index" class="form-control" rows="3"
                                    v-model="formData.samples[index].props[field.fieldName]" :required="field.required"
                                    :disabled="field.isAutomatic"></textarea>
                            </template>
                            <template v-else-if="field.fieldType === 'checkbox'">
                                <label :for="'sample-' + field.fieldName + '-' + index" class="checkbox-lable">
                                    <input type="checkbox" :id="'sample-' + field.fieldName + '-' + index"
                                        v-model="formData.samples[index].props[field.fieldName]"
                                        :disabled="field.isAutomatic" />
                                    {{ field.label }} <span v-if="field.isAutomatic">(automatisch befüllt)</span>
                                </label>
                            </template>
                            <template v-else>
                                <label :for="'sample-' + field.fieldName + '-' + index">{{ field.label }} <span
                                        v-if="field.isAutomatic">(automatisch befüllt)</span></label>
                                <input type="text" :id="'sample-' + field.fieldName + '-' + index"
                                    v-model="formData.samples[index].props[field.fieldName]" :required="field.required"
                                    :disabled="field.isAutomatic" />
                            </template>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="sample-response">Assistant:</label>
                    <AutoGrowTextarea id="sample-response" class="form-control" rows="3"
                        v-model="formData.samples[index].response" />
                </div>
                <div class="text-right pb-1">
                    <button class="btn btn-sm btn-outline-danger" type="button"
                        @click="formData.samples.splice(index, 1)">
                        Löschen
                    </button>
                </div>
            </div>
            <div class="text-center pb-4 pt-4">
                <button class="btn btn-sm btn-outline-primary" type="button" @click="addSample()">Beispiel
                    hinzufügen</button>
            </div>
        </div>
    </div>
</template>

<style scoped>
.sample-container {
    padding: 12px 20px;
    background-color: #eee;

    .sample-prop-container {
        border: 1px solid #ccc;
        padding: 10px;
        margin: 10px 0;
        background-color: #fff;

        .form-group:last-child {
            margin-bottom: 0;
        }

        .checkbox-lable {
            margin-bottom: 0;
        }
    }

    &:nth-child(odd) {
        background-color: #ccc;

        .sample-prop-container {
            background-color: #ddd;
        }
    }
}
</style>
